$textColor: #393939;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*{
  box-sizing: border-box;
}

  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#tsparticles {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.app{
  display: flex;
  flex-direction: column;
}
header{
  display: flex;
  padding: 2rem;
  flex-direction: column;
  align-items: center;

  img{
    max-width: 150px;
    border-radius: 100%;
    transition: all ease .3s;
    box-shadow: 1px 0px 18px 2px rgb(255 255 255 / 58%);
  }
  h2{
    color: white;
  }
  p{
    color: #cbcbcb;
    margin: 0;
    margin-top: 1rem;
    transition: all ease .3s;

  }
}
header.active{

  flex-direction: row;
  gap: 1rem;
  transition: all ease .3s;
  img{
    max-width: 50px;
    transition: all ease .4s;
  }
  p{
    display: none;
    transition: all ease .4s;
  }
}
main {
  p{
    color: $textColor;
    text-align: center;
    margin-top: 0.2rem;
    font-size: large;
  }
  
  display: flex;
  flex-direction: column;
  /* padding: 1rem; */
  gap: 1rem;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 2rem;
  
  .card-container {
    margin: 0 1rem;
    padding: 1rem;
    border-radius: 0.8rem;
    background: #ffffff8f;
    cursor: pointer;

    :hover{
      .card-icon {
        filter: grayscale(0);
      }
      .card-title{
        font-weight: bold;
      }
    }
  }
  .card-container.active {
    margin: 0 ;
    padding: 1rem;
    border-radius: 0.8rem;
    background: #ffffff8f;

    .card-icon {
      filter: grayscale(0);
    }
    .card-title{
      font-size: larger;
      font-weight: bold;
    }
  }
  .card-button {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 50px;
    padding-left: 2rem;
  }
  .card-button.box {
    padding-left: 0;
  }
  .card-button.active{
    padding: 1rem 0;
  }
  .card-body {
    padding: 1rem 0;
    display: flex;
    transition: all ease .4s;
    flex-direction: column;
  }
  .card-icon {
    //filter: grayscale(1);
    flex: 1;
    display: flex;
    justify-content: center;
    margin-right: 2rem;

    img{
      max-width: 75px;
      max-height: 50px;
    }
  }
  .card-title {
    flex: 2;
    color: #25394c;
  }
  
}
footer{
  margin: 1rem 0 2rem 0;
  .links {
    
    display: flex;
    justify-content: center;
    gap: 1rem;
    font-size: x-large;
    color: white;
    opacity: 0.7;
    a{
      text-decoration: none;
      color: inherit;
      &:hover{
      opacity: 1;
      }
    }
  }
}
.btn{
  color: white;
  cursor: pointer;
  &.btn-primary{
    text-decoration: none;
    border: 1px solid rgba(255,140,0,1);
    padding: 0.5rem 1rem;
    background: rgba(255,140,0,1);
  }
}
